import React from "react";
import { InstagramOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { any, string } from "prop-types";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { getSubmitLink } from "../../../utils/campaignFlowTypes";

function CopySubmitLink({ post, text, campaignFlow }) {
  if (!post) return null;
  return (
    <Button
      key="instagram"
      icon={<InstagramOutlined />}
      onClick={async () => {
        await copyToClipboard(
          getSubmitLink({
            onboardId: post.onboardId,
            campaignFlowType: campaignFlow.type,
          })
        );
      }}
    >
      {text}
    </Button>
  );
}

CopySubmitLink.propTypes = {
  post: any,
  onboardId: string,
  text: string,
  campaignFlow: any,
};

export default CopySubmitLink;
