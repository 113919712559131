import React from "react";
import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import { bool } from "prop-types";
import { getBreadcrumbProps } from "../../../AppRouter";
import Balances from "../PaypalService/Balances";

const ServiceSearchHeader = ({ showBalances }) => {
  const history = useHistory();
  return (
    <div className="main-no-padding border">
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title="Paypal"
      >
        {showBalances && <Balances />}
      </PageHeader>
    </div>
  );
};

ServiceSearchHeader.propTypes = {
  showBalances: bool,
};

export default ServiceSearchHeader;
