import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";

export const types = {
  GET_BALANCES_SUCCESS: "GET_BALANCES_SUCCESS", // balances of active payoutAccounts
};

/*
 * ACTIONS
 */
export const getBalancesSuccess = data => {
  return { type: types.GET_BALANCES_SUCCESS, data };
};

export const getBalances = () => async dispatch => {
  const loadingId = "getBalances";
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/services/paypal/balances`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getBalancesSuccess(json));
  } catch (err) {
    console.error(err);
    dispatch(
      result({
        status: `error`,
        title: `Get Paypal balances failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};
