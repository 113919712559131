import {
  Input,
  PageHeader,
  Space,
  Select,
  Form,
  message,
  Button,
  Alert,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleTwoTone,
  CopyOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import styles from "./Payouts.module.css";
import { getInvitePayouts } from "../../redux/payouts/payoutActions";
import { updateInvites } from "../../redux/invites/invitesActions";
import InstagramDisqualify from "./InstagramDisqualify";
import { copyToClipboard } from "../../utils/copyToClipboard";

export default function ManualPayouts() {
  const dispatch = useDispatch();
  const [numOfManualPayouts, setNumOfManualPayouts] = useState(0);
  const [allPayoutAccountIds, setAllPayoutAccountIds] = useState([]);
  const [payoutAccountId, setPayoutAccountId] = useState("");
  const [payoutsData, setPayoutsData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [disqualifyComponentVisible, setDisqualifyComponentVisible] =
    useState(false);
  const [form] = Form.useForm();

  const currentInvite =
    payoutsData && payoutAccountId ? payoutsData[payoutAccountId][0] : {};

  const _copyToClipboard = async label => {
    const value = form.getFieldValue(label);
    await copyToClipboard(value);
  };

  // copys JSON array of form values for Selenium script
  const copyScriptToClipboard = async () => {
    const formKeysToCopy = ["paypalEmail", "payoutAmount", "note", "firstName"];
    const valuesArray = [];
    for (const formKey of formKeysToCopy) {
      const value = form.getFieldValue(formKey);
      valuesArray.push(value);
    }

    await navigator.clipboard.writeText(JSON.stringify(valuesArray));
    message.success(`${form.getFieldValue(formKeysToCopy[0])} script copied`);
  };

  const invitePayouts = useSelector(state => state.payouts.invitePayouts);
  const isLoading = useSelector(state => state.shared.isLoading);

  useEffect(() => {
    dispatch(getInvitePayouts());
  }, [dispatch]);

  useEffect(() => {
    if (!payoutAccountId && invitePayouts?.length) {
      setNumOfManualPayouts(invitePayouts.length);
    }
  }, [invitePayouts, payoutAccountId]);

  useEffect(() => {
    if (invitePayouts?.length) {
      const newPayoutsData = {};
      for (const invite of invitePayouts) {
        if (!newPayoutsData[invite.payoutAccountId]) {
          newPayoutsData[invite.payoutAccountId] = [];
        }
        newPayoutsData[invite.payoutAccountId].push(invite);
      }
      setAllPayoutAccountIds(
        Object.keys(newPayoutsData).map(key => {
          return {
            value: key,
          };
        })
      );
      setPayoutsData(newPayoutsData);
    }
  }, [invitePayouts]);

  useEffect(() => {
    if (payoutAccountId && payoutsData) {
      const numOfPayouts = payoutsData[payoutAccountId].length;
      if (numOfPayouts > 0) {
        setNumOfManualPayouts(numOfPayouts);
        // order by userEventId
        const userEvents =
          currentInvite.relations?.users?.relations?.userEvents?.sort(
            (a, b) => b.userEventId - a.userEventId
          );
        let _disqualifyComponentVisible = false;
        if (userEvents?.length > 0) {
          /*
          This was a temporary, hack-job solution for inviting past users
          that have been invited before we implemented user_event table
          we then invited them again after a long hiatus of no action.

          We can flag these users based on their userEvent array.
          The very first event should be the below. Other users
          that went through our normal process will have a userEvent
          with a previousUserState of joined
          */
          _disqualifyComponentVisible =
            userEvents[0].previousUserState === "invited" &&
            userEvents[0].eventCode === "invited";
        }
        setDisqualifyComponentVisible(_disqualifyComponentVisible);
        form.setFieldsValue({
          paypalEmail: currentInvite.paypalEmail,
          payoutAmount: (
            Math.round(currentInvite.payoutAmount * 100) / 100
          ).toFixed(2), // format as currency with 2 decimals
          note: currentInvite.note,
          firstName: currentInvite.relations?.users?.firstName,
        });
      } else {
        setNumOfManualPayouts(0);
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutAccountId, payoutsData]);

  const submitInvite = async ({ error } = {}) => {
    try {
      setErrorMessage("");
      let updateResult = null;
      if (error) {
        const errorInviteBody = {
          inviteId: currentInvite.inviteId,
          inviteState: "errored",
          payoutAccountId: Number(payoutAccountId),
        };
        updateResult = await dispatch(
          updateInvites(errorInviteBody, currentInvite.inviteId)
        );
      } else {
        const submitInviteBody = [
          {
            inviteId: currentInvite.inviteId,
            payoutAccountId: Number(payoutAccountId),
            force: true,
          },
        ];
        updateResult = await dispatch(updateInvites(submitInviteBody));
      }
      if (updateResult?.errorCount > 0) {
        setErrorMessage(updateResult.errors[currentInvite.inviteId]);
        return;
      }
      moveToNextInvite();
    } catch (err) {
      setErrorMessage(err?.message || "An unknown error occurred");
    }
  };

  const moveToNextInvite = () => {
    const newPayoutsData = { ...payoutsData };
    payoutsData[payoutAccountId].shift();
    setPayoutsData(newPayoutsData);
    message.success("Invite updated!");
  };

  return (
    <div>
      <div className="main-no-padding border">
        <PageHeader
          extra={
            <Space align="center">
              <Form.Item label="Payout Account ID" style={{ marginBottom: 0 }}>
                <Select
                  options={allPayoutAccountIds}
                  style={{ width: 100 }}
                  value={payoutAccountId}
                  onChange={setPayoutAccountId}
                />
              </Form.Item>
            </Space>
          }
          ghost={false}
          title={<span>Manual Payouts ({numOfManualPayouts})</span>}
        />
      </div>

      <div className="main-no-padding border">
        {numOfManualPayouts === 0 && !isLoading && (
          <div className={styles.formContainer}>
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              className={styles.checkIcon}
            />{" "}
            <h2>All payouts done!</h2>
          </div>
        )}
        {disqualifyComponentVisible && numOfManualPayouts > 0 && (
          <InstagramDisqualify
            currentInvite={currentInvite}
            moveToNextInvite={moveToNextInvite}
          />
        )}
        {numOfManualPayouts > 0 && (
          <Form
            labelAlign="right"
            layout="horizontal"
            className={styles.formContainer}
            labelCol={{
              xs: { span: 8 },
              md: { span: 6 },
              lg: { span: 6 },
            }}
            wrapperCol={{
              xs: { span: 14 },
              md: { span: 16 },
              lg: { span: 14 },
            }}
            form={form}
          >
            <Space direction="vertical" size="large" className={styles.form}>
              <Form.Item label="Paypal Email" name="paypalEmail">
                <Input
                  disabled
                  extra={<Button type="primary" icon={<RobotOutlined />} />}
                  suffix={
                    payoutAccountId && (
                      <Space size="middle">
                        <Button
                          onClick={() => copyScriptToClipboard()}
                          style={{ width: "48px" }}
                          type="primary"
                          icon={<RobotOutlined />}
                        />
                        <CopyOutlined
                          className={styles.copyIcon}
                          onClick={() => _copyToClipboard("paypalEmail")}
                        />
                      </Space>
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                label="Payout Amount"
                name="payoutAmount"
                extra={
                  currentInvite?.payoutAmount > 60 ? (
                    <Alert
                      message="Payout amount is high!"
                      type="warning"
                      showIcon
                      closable
                    />
                  ) : undefined
                }
              >
                <Input
                  disabled
                  suffix={
                    payoutAccountId && (
                      <CopyOutlined
                        className={styles.copyIcon}
                        onClick={() => _copyToClipboard("payoutAmount")}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item label="Note" name="note">
                <Input
                  disabled
                  suffix={
                    payoutAccountId && (
                      <CopyOutlined
                        className={styles.copyIcon}
                        onClick={() => _copyToClipboard("note")}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item label="First Name" name="firstName">
                <Input
                  disabled
                  suffix={
                    payoutAccountId && (
                      <CopyOutlined
                        onClick={() => _copyToClipboard("firstName")}
                        className={styles.copyIcon}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: { offset: 7 },
                  sm: { offset: 14 },
                  md: { offset: 15 },
                  lg: { offset: 15 },
                }}
              >
                <Button
                  style={{ marginRight: "20px" }}
                  type="default"
                  onClick={() => submitInvite({ error: true })}
                  disabled={!payoutAccountId || isLoading}
                >
                  Error
                </Button>
                <Button
                  icon={<CheckCircleOutlined />}
                  type="primary"
                  onClick={submitInvite}
                  disabled={!payoutAccountId || isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </Form>
        )}
        {errorMessage && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {errorMessage}
          </h4>
        )}
      </div>
    </div>
  );
}
