import React, { useState } from "react";
import { Button, Select, Tag } from "antd";
import { FileProtectOutlined } from "@ant-design/icons";
import DataTable from "../shared/DataTable/DataTable";
import GenericPageHeader from "../shared/GenericPageHeader/GenericPageHeader";
import { useContractDrafts } from "../../api/contractDrafts";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import { displayDate } from "../../utils/dates";
import RetoolIcon from "../../assets/retool.png";
import {
  CreateContractDraftButton,
  EditContractDraftButton,
} from "./ContractDraftButton";

const pageSize = 300;
export default function ContractDraftsTable() {
  const [filter, setFilter] = useState("new_or_pending");

  const { data: contractDrafts, isLoading } = useContractDrafts();

  if (!contractDrafts) return null;

  const filteredContractDrafts = contractDrafts.filter(contractDraft => {
    const currentVersion = getCurrentVersion(contractDraft);
    if (filter === "new_or_pending") {
      return ["new", "pending_internal", "pending_external"].includes(
        currentVersion.state
      );
    }

    return currentVersion.state === filter;
  });

  return (
    <>
      <GenericPageHeader
        ghost={false}
        showBreadcrumb
        title="Contract Drafts"
        extra={
          <>
            {/** Filter select */}
            <Select
              value={filter}
              onChange={val => setFilter(val)}
              style={{ width: 150 }}
              options={[
                {
                  label: "new or pending",
                  value: "new_or_pending",
                },
                {
                  value: "new",
                },
                {
                  label: "pending internal",
                  value: "pending_internal",
                },
                {
                  label: "pending external",
                  value: "pending_external",
                },
                {
                  value: "active",
                },
                {
                  value: "void",
                },
              ]}
            />
            <CreateContractDraftButton />
          </>
        }
      />
      <DataTable
        isLoading={isLoading}
        size="small"
        dataSource={filteredContractDrafts}
        rowKey="contractDraftId"
        pagination={{
          pageSize,
          showSizeChanger: false,
        }}
        columns={getColumnsFromDataSource(
          contractDrafts,
          [
            "displayName",
            "currentVersion",
            "createdAt",
            "state",
            "assigned",
            "noteInternal",
            "source",
            "retool",
            "edit",
          ],
          {
            displayName: {
              title: "Name",
              render: (text, record) => (
                <a
                  href={getBrandEditLink(record)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            },
            currentVersion: {
              title: "V", // shortened title for small integer column value eg. 1
            },
            state: {
              render: (text, record) => {
                const currentVersion = getCurrentVersion(record);
                return (
                  <Tag color={getTagColor(currentVersion)}>
                    {currentVersion.state}
                  </Tag>
                );
              },
            },
            createdAt: {
              render: text => `${displayDate(text).format("MMM Do YYYY")}`,
            },
            assigned: {
              render: (_, record) => getCurrentVersion(record).assigned,
            },
            noteInternal: {
              render: (_, record) => getCurrentVersion(record).noteInternal,
            },
            source: {
              render: (_, record) => (
                <Button
                  onClick={() => {
                    window.open(
                      `https://app.pandadoc.com/a/#/documents/${record.sourceId}`,
                      "_blank"
                    );
                  }}
                >
                  <FileProtectOutlined />
                  Source
                </Button>
              ),
            },
            retool: {
              render: (_, record) => (
                <Button
                  onClick={() => {
                    window.open(
                      `https://stackdev.retool.com/app/drafts?contractDraftId=${record.contractDraftId}`
                    );
                  }}
                  icon={
                    <img
                      style={{ margin: 4 }}
                      width="12"
                      height="12"
                      alt="Retool Button"
                      src={RetoolIcon}
                    />
                  }
                >
                  Retool
                </Button>
              ),
            },
            edit: {
              render: (_, record) => (
                <EditContractDraftButton contractDraft={record} />
              ),
            },
          }
        )}
      />
    </>
  );
}

function getCurrentVersion(contractDraft) {
  return contractDraft.relations.contractDraftVersions[0];
}

function getTagColor(currentVersion) {
  const { state } = currentVersion;

  switch (state) {
    case "new":
      return "blue";
    case "pending_internal":
    case "pending_external":
      return "orange";
    case "active":
      return "green";
    case "void":
      return "red";
    default:
      return "grey";
  }
}

function getBrandEditLink(contractDraft) {
  return `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}/brand/contractDrafts/${contractDraft.contractDraftId}/form`;
}
