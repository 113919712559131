import { types as payoutTypes } from "./payoutActions";
import { types as serviceTypes } from "./serviceActions";

//merge 2 actions files
const types = {
  ...payoutTypes,
  ...serviceTypes,
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_PAYOUTS_SUCCESS:
      return { ...state, payoutsCount: action.data.length };
    case types.GET_INVITE_PAYOUTS_SUCCESS:
      return {
        ...state,
        invitePayouts: action.data,
        invitePayoutsCount: action.data.length,
      };
    case types.GET_PAYPAL_TRANSACTIONS_SUCCESS:
      return { ...state, paypalTransactionsCount: action.data.length };
    case types.GET_BALANCES_SUCCESS:
      return { ...state, balances: action.data };
    default:
      return state;
  }
};
