import { message } from "antd";

export const copyToClipboard = async value => {
  await navigator.clipboard.writeText(value);
  message.success(`'${value}' copied`);
};

/**
 * Attempts to copy both HTML and plain text representations to the clipboard.
 * - In rich text editors (e.g., Gmail, Slack), it appears as a clickable link.
 * - In plain text editors (e.g., Notepad), it appears as plain text.
 *
 * Falls back to copying plain text if the Clipboard API is unavailable or fails.
 */
export const copyFormattedLinkToClipboard = async ({ text, url }) => {
  // HTML version of the link
  const htmlContent = `<a href="${url}">${text}</a>`;

  // Check if the Clipboard API and ClipboardItem are supported
  if (navigator.clipboard && window.ClipboardItem) {
    try {
      // Create a ClipboardItem with both HTML and plain text
      const clipboardItem = new window.ClipboardItem({
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob([text], { type: "text/plain" }),
      });

      // Write the ClipboardItem to the clipboard
      await navigator.clipboard.write([clipboardItem]);

      message.success(`'${text}' link copied`);
    } catch (error) {
      // Fallback to plain text copy if Clipboard API fails
      copyToClipboard(text);
    }
  } else {
    // Fallback to plain text copy if Clipboard API is not supported
    copyToClipboard(text);
  }
};
