import React from "react";
import { useSelector } from "react-redux";
import PayoutHeader from "./PayoutHeader/ServiceSearchHeader";
import PayoutsReconcileHeader from "./PayoutHeader/PayoutsReconcileHeader";
import TransactionsReconcileHeader from "./PayoutHeader/TransactionsReconcileHeader";
import checkAuth from "../../utils/checkAuth";

export default function PayoutsHome() {
  const roles = useSelector(state => state.auth.roles);
  const hasAdminRole = checkAuth.has(roles, ["admin"]);

  //on unmount, reset posts
  return (
    <div>
      <PayoutHeader showBalances={hasAdminRole} />

      {hasAdminRole && (
        <div>
          <TransactionsReconcileHeader />
          <PayoutsReconcileHeader />
          {/* <ManualPayoutsHeader /> */}
        </div>
      )}
    </div>
  );
}
