import React, { useState } from "react";
import { Form, Select, Button, Space, Typography } from "antd";
import { array, bool, object, string } from "prop-types";
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { selectFilter } from "../../utils/filters";
import styles from "./Test.module.css";
import { defaultButtonWrapperCol } from "../../utils/formLayout";
import { usePushEmailGroups } from "../../api/emailGroups";
import DataTable from "../shared/DataTable/DataTable";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { useCreateInvite } from "../../api/invites";

function InvitesForm({
  loading,
  inviteGroupOptions,
  woodpeckerCampaignOptions,
  buttonDisabled,
  disabled,
  user,
  selectedWoodpeckerCampaignId,
  selectedInviteGroupId,
  campaignOptions,
}) {
  const existingInvites = user?.relations?.invites?.map(invite => {
    const selectedOption = campaignOptions?.find(
      o => o.value === invite.campaignId
    );
    const campaignName = selectedOption ? selectedOption.label : "";
    return {
      inviteId: invite.inviteId,
      link: `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/invites?_i_id=${invite.inviteId}`,
      campaignName,
      state: invite.inviteState,
    };
  });
  const pushEmailGroups = usePushEmailGroups();
  const createInvite = useCreateInvite();

  const { isError, isLoading, error } = pushEmailGroups;
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const sendInvite = async () => {
    setSuccess(false);
    setFailure(false);
    if (selectedWoodpeckerCampaignId) {
      const inviteEmailGroupPushRequest = {
        type: "invite",
        woodpeckerCampaigns: {
          [selectedWoodpeckerCampaignId]: 1,
        },
        userIds: [user.userId],
      };
      const inviteResponse = await pushEmailGroups.mutateAsync(
        inviteEmailGroupPushRequest
      );
      if (
        selectedWoodpeckerCampaignId &&
        inviteResponse?.woodpeckerCampaigns[selectedWoodpeckerCampaignId]
          .emailJobLog
      ) {
        const { emailJobLog } =
          inviteResponse.woodpeckerCampaigns[selectedWoodpeckerCampaignId];
        if (emailJobLog.successes >= emailJobLog.failures) {
          setSuccess(true);
        } else {
          setFailure(true);
        }
      }
    } else {
      const inviteCreationRequest = {
        inviteGroups: {
          [selectedInviteGroupId]: 1,
        },
        userIds: [user.userId],
      };
      try {
        await createInvite.mutateAsync(inviteCreationRequest);
        setSuccess(true);
      } catch (err) {
        setFailure(true);
      }
    }
  };

  return (
    <>
      <Form.Item label="Invite" className={styles.formItem}>
        <Form.Item
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          <Form.Item name="inviteGroupId" className={styles.formItem}>
            <Select
              disabled={disabled}
              filterOption={selectFilter}
              loading={loading}
              showSearch
              className={styles.fullWidth}
              placeholder="Invite Group Name"
              options={inviteGroupOptions}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="inviteWoodpeckerCampaignId"
          placeholder="Woodpecker Campaign ID"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <Select
            disabled={disabled}
            filterOption={selectFilter}
            loading={loading}
            showSearch
            className={styles.fullWidth}
            placeholder="Woodpecker Campaign ID"
            options={woodpeckerCampaignOptions}
          />
        </Form.Item>
      </Form.Item>
      {existingInvites && existingInvites.length > 0 && (
        <Form.Item label={<div />} colon={false}>
          <Space align="center">
            <Typography.Text strong>Current Invites</Typography.Text>
          </Space>
          <div className={styles.eventsContainer}>
            <DataTable
              size="small"
              style={{ marginBottom: "12px" }}
              dataSource={existingInvites}
              rowClassName="compact-row"
              rowKey={row => `${row.inviteId}`}
              pagination={false}
              columns={getColumnsFromDataSource(
                existingInvites,
                ["inviteId", "campaignName", "link", "state"],
                {
                  inviteId: {
                    title: "ID",
                    render: inviteId => (
                      <CopyOutlined
                        onClick={() => {
                          copyToClipboard(inviteId, "invite id");
                        }}
                      />
                    ),
                  },
                  link: {
                    // eslint-disable-next-line react/display-name
                    render: value => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                        href={value}
                      >
                        {value}
                      </a>
                    ),
                  },
                }
              )}
            />
          </div>
        </Form.Item>
      )}
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button
          loading={isLoading}
          disabled={disabled || buttonDisabled}
          onClick={sendInvite}
          type="primary"
        >
          Send Invite
        </Button>
        {isError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {error.message}
          </h4>
        )}
        {success && (
          <h4 className={styles.success}>
            <CheckOutlined fill="green" /> Success
          </h4>
        )}
        {failure && (
          <h4 className={styles.error}>
            <CloseOutlined fill="red" /> Failure
          </h4>
        )}
      </Form.Item>
    </>
  );
}

InvitesForm.propTypes = {
  loading: bool,
  inviteGroupOptions: array,
  woodpeckerCampaignOptions: array,
  buttonDisabled: bool,
  disabled: bool,
  user: object,
  selectedWoodpeckerCampaignId: string,
  selectedInviteGroupId: string,
  campaignOptions: array,
};

export default InvitesForm;
