/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import {
  CheckCircleTwoTone,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Tooltip,
  Empty,
  Space,
  Tag,
  Typography,
  Popover,
  Spin,
  Divider,
} from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { array } from "prop-types";
import DataTable from "../shared/DataTable/DataTable";
import EditPayout from "./ActionButtons/EditPayout";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import clipboard from "../../utils/clipboard";
import { camelCaseToSentenceCase } from "../../utils/stringUtils";
import checkAuth from "../../utils/checkAuth";
import amountToSendIncludingFees from "../Payouts/utils/amountToSendIncludingFees";
import { getPayoutDetails } from "../../redux/payouts/payoutActions";

const PayoutsDataTable = props => {
  //redux
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.shared.isLoading);
  const roles = useSelector(state => state.auth.roles);
  const { payouts } = props;
  const [payoutDetails, setPayoutDetails] = useState([]);

  useEffect(() => {
    if (payouts && payouts.length) {
      const newPayoutDetails = [];
      for (const payout of payouts) {
        newPayoutDetails.push({});
      }
      setPayoutDetails(newPayoutDetails);
    }
  }, [payouts]);

  const getDetailsForPayout = async index => {
    if (!payoutDetails[index].status) {
      let newPayoutDetails = [...payoutDetails];
      newPayoutDetails[index].status = "loading";
      setPayoutDetails(newPayoutDetails);
      try {
        newPayoutDetails[index].value = await dispatch(
          getPayoutDetails(
            payouts[index].payoutAccountId,
            payouts[index].paypalPayoutId
          )
        );
        newPayoutDetails[index].status = "success";
      } catch (err) {
        newPayoutDetails[index].status = "rejected";
      }
      newPayoutDetails = [...newPayoutDetails];
      setPayoutDetails(newPayoutDetails);
    }
  };

  const generatePayoutsPopover = index => {
    if (payoutDetails && payoutDetails[index]) {
      if (
        !payoutDetails[index].status ||
        payoutDetails[index].status === "loading"
      ) {
        return (
          <Popover
            onVisibleChange={() => getDetailsForPayout(index)}
            trigger="click"
            content={<Spin tip="Loading..." />}
          >
            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
          </Popover>
        );
      }

      if (payoutDetails[index].status === "rejected") {
        return (
          <Popover
            trigger="click"
            content={
              <Typography.Text type="danger">
                An error occurred when trying to fetch payout details for this
                payout
              </Typography.Text>
            }
          >
            <WarningOutlined style={{ marginLeft: "8px", color: "red" }} />
          </Popover>
        );
      }
      const details = payoutDetails[index].value;

      const payoutDetailItem = details.items[0];

      if (payoutDetailItem) {
        return (
          <Popover
            trigger="click"
            content={
              <div>
                <Typography
                  onClick={() => clipboard(payoutDetailItem.transaction_id)}
                >
                  <strong>transaction id:</strong>{" "}
                  {payoutDetailItem.transaction_id}
                </Typography>
                <Typography
                  onClick={() => clipboard(payoutDetailItem.transaction_status)}
                >
                  <strong>transaction status:</strong>{" "}
                  {payoutDetailItem.transaction_status}
                </Typography>
                <Typography
                  onClick={() =>
                    clipboard(payoutDetailItem.payout_item.receiver)
                  }
                >
                  <strong>receiver:</strong>{" "}
                  {payoutDetailItem.payout_item.receiver}
                </Typography>
                <Typography
                  onClick={() =>
                    clipboard(payoutDetailItem.payout_item.amount.value)
                  }
                >
                  <strong>amount:</strong>{" "}
                  {payoutDetailItem.payout_item.amount.value}
                </Typography>
                {payoutDetailItem.errors && (
                  <>
                    <Typography
                      onClick={() => clipboard(payoutDetailItem.errors.name)}
                    >
                      <strong>error name:</strong>{" "}
                      {payoutDetailItem.errors.name}
                    </Typography>
                    <Typography
                      onClick={() => clipboard(payoutDetailItem.errors.message)}
                    >
                      <strong>error messsage:</strong>{" "}
                      {payoutDetailItem.errors.message}
                    </Typography>
                  </>
                )}
              </div>
            }
          >
            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
          </Popover>
        );
      }
    }
    return null;
  };

  //only render if data loaded
  if (
    (!isLoading && (!payouts || !payouts.length)) ||
    !checkAuth.has(roles, ["manager"])
  )
    return <Empty className="centered" />;

  return (
    <div>
      <Divider orientation="left">Payouts</Divider>
      <DataTable
        size="small"
        dataSource={payouts}
        rowKey="payoutId"
        pagination={false}
        columns={getColumnsFromDataSource(
          payouts,
          //column names
          [
            "payoutAccountId",
            "payoutType",
            "upfront",
            "manual",
            "payout",
            "payoutDate",
            "paypalEmail",
            "transactionId",
            "paypalPayoutId",
            "refundId",
            "r", //metadata.resolved
            "edit",
          ],
          {
            payoutType: {
              render: text => camelCaseToSentenceCase(text),
            },
            payoutAccountId: {
              title: "#",
              width: "24px",
            },
            upfront: {
              render: text =>
                text ? (
                  <Tag color="blue">Upfront</Tag>
                ) : (
                  <Tag color="red">Rebate</Tag>
                ),
            },
            manual: {
              onCell: (record, rowIndex) => ({
                onClick: e => {
                  clipboard(amountToSendIncludingFees(record.payout));
                }, //copy to clipboard
              }),
              render: (text, record) =>
                text ? (
                  `$${amountToSendIncludingFees(record.payout)}`
                ) : (
                  <Tag>API</Tag>
                ),
            },
            payout: {
              render: text => `$${text}`,
            },
            payoutDate: {
              render: text =>
                text && (
                  <Space>
                    {moment(text).format("ll")}
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  </Space>
                ),
            },
            paypalEmail: {
              render: (text, record) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      onClick={() => clipboard(record.paypalEmail)}
                    >
                      {text}
                    </Typography.Text>
                  </div>
                );
              },
            },
            transactionId: {
              render: (text, record) => {
                if (!record.transactionId) {
                  const _36hoursAgo = moment().subtract(36, "h");
                  if (moment(record.payoutDate).isSameOrAfter(_36hoursAgo)) {
                    return (
                      <Typography.Text
                        type="secondary"
                        style={{ fontStyle: "italic" }}
                      >
                        Reconciliation pending
                      </Typography.Text>
                    );
                  }
                  if (
                    moment(record.payoutDate).isBefore(_36hoursAgo) &&
                    !record.metadata?.resolved
                  ) {
                    return (
                      <Typography.Text
                        type="danger"
                        style={{ fontStyle: "italic" }}
                      >
                        {"Unresolved > 36hrs"}
                      </Typography.Text>
                    );
                  }
                }
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      onClick={() => clipboard(record.transactionId)}
                    >
                      {text}
                    </Typography.Text>
                  </div>
                );
              },
            },
            paypalPayoutId: {
              title: "Automated Id / Errors",
              render: (text, record, rowIndex) => {
                //if rebate error
                if (
                  !record.paypalPayoutId &&
                  record.metadata &&
                  record.metadata.errorMessage &&
                  record.metadata.resolved !== true
                ) {
                  return (
                    <Typography.Text type="danger">
                      {record.metadata.errorMessage}
                    </Typography.Text>
                  );
                }
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      onClick={() => clipboard(record.paypalPayoutId)}
                    >
                      {text}
                    </Typography.Text>
                    {text && generatePayoutsPopover(rowIndex)}
                  </div>
                );
              },
            },
            refundId: {
              onCell: (record, rowIndex) => ({
                onClick: e => {
                  clipboard(record.refundId);
                }, //copy to clipboard
              }),
            },
            r: {
              render: (text, record) => {
                if (!record.metadata) return null;
                return (
                  <Tooltip
                    placement="left"
                    title={
                      record.metadata.comment || record.metadata.errorMessage
                    }
                  >
                    <Typography.Text
                      type={record.metadata.resolved ? null : "danger"}
                    >
                      {record.metadata.resolved ? "T" : "F"}
                    </Typography.Text>
                  </Tooltip>
                );
              },
            },
            edit: {
              render: (text, record) => <EditPayout payout={record} />,
            },
          }
        )}
      />
    </div>
  );
};

PayoutsDataTable.propTypes = {
  payouts: array,
};

export default PayoutsDataTable;
